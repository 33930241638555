<template>
    <main id="analyse_sanitaire" v-cloak>
        <HeaderTab :title="$t('global.analyse_sanitaire')" :swiper_tabs="swiper_tabs" />

        <div id="content">
            <div class="row">
                <div class="col-12">
                    <div>
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "AnalyseSanitaire",
		mixins: [Navigation],
		data () {
			return {
                param_tab: '',
                actual_tab: 'demande_analyse',
                swiper_tabs: [],
			}
		},

		mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.swiper_tabs = [
                {
                    id: 'demande_analyse',
                    label: 'global.demande_analyse',
                    active: true,
                    href : 'DemandeAnalyse'
                },
                {
                    id: 'resultat_sanitaire',
                    label: 'global.resultat_sanitaire',
                    active: false,
                    href : 'ResultatSanitaire'
                }
            ]
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
